/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

export default {
  contact_us: 'تواصل معنا',
  about_us: 'من نحن',
  vision: 'رؤيتنا',
  mission: 'هدفنا',
  our_logo: 'شعارنا',
  follow_us: 'تابعنا',
  mail_us_your_message: 'ارسل رسالتك',
};

<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2020  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <v-card
      :flat="flat"
      class="d-inline-flex flex-column text-center pa-2"
      color="transparent"
  >
    <div class="mb-5">
      <v-avatar color="grey" size="200">
        <v-img :max-height="maxHeight" :max-width="maxWidth" :src="value" :width="width" contain />
      </v-avatar>
    </div>
    <div>
      <app-btn
          :color="color"
          @click="clickFileInput"
          v-bind="$attrs"
      >
        <app-icon>mdi-pen</app-icon>
        {{ btnLabel }}
      </app-btn>
      <input
          @change="setupCropper($event.target)" accept="image/*" class="input-file" ref="inputFile"
          type="file"
      />
    </div>
    <v-dialog max-width="900" persistent v-model="dialog">
      <v-card flat>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <v-container>
            <v-row align="center" justify="center">
              <app-col all="5" class="app-col" sm="12">
                <div class="mb-2">
                  <img :src="objectUrl" alt="" class="image-preview elevation-3" ref="source" />
                </div>
                <div class="text-center">
                  <v-divider></v-divider>
                  <app-btn @click="resetCropper" icon min-width="auto" small>
                    <v-icon>mdi-aspect-ratio</v-icon>
                  </app-btn>
                  <app-btn @click="rotateLeft" icon min-width="auto" small>
                    <v-icon>mdi-rotate-left</v-icon>
                  </app-btn>
                  <app-btn @click="rotateRight" icon min-width="auto" small>
                    <v-icon>mdi-rotate-right</v-icon>
                  </app-btn>
                </div>
              </app-col>
              <v-spacer></v-spacer>
              <app-col all="5" class="app-col" sm="12">
                <img :src="previewCropped" alt="" class="image-preview elevation-5" />
              </app-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-container fluid>
            <v-row no-gutters>
              <app-col all="12">
                <app-btn :loading="savingCropped" @click="saveCropped" color="primary">{{ $t('save') }}</app-btn>
                <app-btn :disabled="savingCropped" @click="closeDialog" color="error">{{ $t('cancel') }}</app-btn>
              </app-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import debounce from 'lodash/debounce'
import Cropper from 'cropperjs'

export default {
  name: "AvatarInput",
  props: {
    value: {
      default: () => ''
    },
    btnLabel: {
      default() {
        return this.$t('change')
      }
    },
    fileName: {
      type: String,
      default: () => 'image'
    },
    url: {
      type: String,
      default: () => null
    },
    width: {
      default() {
        return 250
      }
    },
    maxWidth: {
      default() {
        return this.width
      }
    },
    maxHeight: {
      default() {
        return '100%'
      }
    },
    color: {
      type: String,
      default: () => 'primary'
    },
    flat: {
      type: Boolean,
      default: () => !0
    },
  },
  data() {
    return {
      dialog: !1,
      cropper: null,
      objectUrl: null,
      previewCropped: null,
      selectedFile: null,
      debouncedUpdatePreview: debounce(this.updatePreview, 257),
      savingCropped: !1,
    }
  },
  beforeCreate() {
    this.$on('dialog', v => this.dialog = v)
  },
  methods: {
    resetCropper() {
      this.cropper.reset()
    },
    rotateLeft() {
      this.cropper.rotate(-90)
    },
    rotateRight() {
      this.cropper.rotate(90)
    },
    clearCropper() {
      if (this.cropper) {
        this.cropper.destroy()
      }

      if (this.objectUrl) {
        window.URL.revokeObjectURL(this.objectUrl)
      }

      this.cropper = null
      this.objectUrl = null
      this.previewCropped = null
      if (this.$refs.inputFile) {
        this.$refs.inputFile.value = null
      }
    },
    setupCropper(selectedFile) {
      try {
        selectedFile = selectedFile.files[0]
      }
      catch (e) {
        selectedFile = null
      }
      if (!selectedFile) {
        this.closeDialog()
        return
      }

      this.openDialog()

      this.$nextTick(() => {
        (this.objectUrl = window.URL.createObjectURL(selectedFile)) &&
        this.$nextTick(this.setupCropperInstance)

      })
    },
    setupCropperInstance() {
      this.cropper = new Cropper(this.$refs.source, {
        background: !1,
        aspectRatio: 1,
        crop: this.debouncedUpdatePreview
      })
    },
    updatePreview(event) {
      const canvas = this.cropper.getCroppedCanvas()
      this.previewCropped = canvas.toDataURL('image/png')
    },
    clickFileInput() {
      this.$refs.inputFile.click()
    },
    openDialog() {
      this.clearCropper();
      this.dialog = !0
    },
    closeDialog() {
      this.clearCropper();
      this.dialog = !1
    },
    saveCropped() {
      this.savingCropped = !0
      const canvas = this.cropper.getCroppedCanvas()

      canvas.toBlob((blob) => {
        const formData = new FormData()
        formData.append(this.fileName, blob, `${this.fileName}.png`)

        this.$axios.post(this.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
            .then((response) => {
              this.$emit('saved', response)
              // this.$emit('input', 'blob')
            })
            .catch((error) => {
              this.$emit('error', error)
            })
            .finally(() => this.savingCropped = !1)
      })
    },
  }
}
</script>
<style lang="sass" scoped>
.input-file
  display: none

.app-col
  display: inline-block
  padding: 0 5px
  margin-bottom: 8px

.image-preview
  display: block
  max-height: 400px
  max-width: 400px
  min-height: 200px
  min-width: 200px
  width: 100%
  height: 400px
  margin: auto

</style>
<style scoped>
@import '~cropperjs/dist/cropper.css';
</style>

<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2020  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <v-list dense>
    <template v-for="(item, i) in items">
      <app-list-item :item="item"></app-list-item>
    </template>
  </v-list>
</template>

<script>

export default {
  name: "DrawerList",
  props: ['items'],
}
</script>

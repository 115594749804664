<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2020  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <v-btn
      text tile
      v-bind="$attrs"
      v-on="$listeners"
  >
    <slot></slot>
  </v-btn>
</template>

<script>

export default {
  name: "SnackbarBtn",
}
</script>
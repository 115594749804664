<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2020  All rights reserved.
  - MyTh Ahmed
  -->

<template>
    <v-progress-linear
        :active="$root.AppProgress"
        indeterminate
        v-bind="$attrs"
        v-on="$listeners"
        fixed
        class="mb-1"
    ></v-progress-linear>
</template>

<script>
export default {
  name: "AppProgressLinear"
}
</script>

<style scoped>

</style>
/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

export const LOGIN_ROUTE_NAME = 'login'
export const LOGOUT_ROUTE_NAME = 'logout'
export const HOME_ROUTE_NAME = 'home'
export const USER_HOME_ROUTE_NAME = 'dashboard'

const routeAllies = {
  errors: {},
  landing: {
    home: {
      path: HOME_ROUTE_NAME,
      name: HOME_ROUTE_NAME
    },
    blogs: {
      path: '/blogs',
      name: 'home-blogs'
    },
    blog: {
      path: '/blog/:id',
      name: 'home-blog'
    },
  },
  auth: {
    login: LOGIN_ROUTE_NAME,
    logout: {
      path: `/${LOGOUT_ROUTE_NAME}`,
      name: LOGOUT_ROUTE_NAME
    }
  },
  user: {
    home: USER_HOME_ROUTE_NAME,
    profile: 'profile',
    index: 'user-index'
  },
  role: {
    index: 'role-index'
  },
  permission: {
    index: 'permission-index'
  },
  blogCategory: {
    index: 'blog-category-index'
  },
  blog: {
    index: 'blog-index'
  }
}
// console.log(routeAllies)
export default routeAllies

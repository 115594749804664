<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2020  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <v-toolbar :class="{'d-inline-flex': flex }" :color="color" dark v-bind="$attrs" v-on="$listeners">
    <v-toolbar-title>
      <slot></slot>
    </v-toolbar-title>
  </v-toolbar>
</template>

<script>
export default {
  name: "Title",
  props: {
    flex: {
      type: Boolean,
      default: () => !1
    },
    color: {
      type: String,
      default: () => 'accent',
    },
  }
}
</script>
import axios from 'axios'

function appendArray(form_data, values, name) {
  if(values instanceof File) {
    form_data.append(name, values, values.name)
  }
  else if(typeof values == 'object') {
    for(let key in values) {
      // console.log(key);
      if(typeof values[key] === 'object') {
        // console.log(values[key])
        let k
        if(name) {
          k = name + '[' + key + ']'
        }
        else {
          k = key
        }

        appendArray(form_data, values[key], k)
      }
      else {
        if(name) {
          // console.log('name')
          form_data.append(name + '[' + key + ']', values[key])
        }
        else {
          form_data.append(key, values[key])
        }
      }
    }
  }

  return form_data
}

export const Stub = prefix => {
  const url = p => prefix + (p ? `/${p}` : '')
  return {
    index(params, config = {}) {
      let u = url()
      return params === true ? u : axios.get(u, {...config, params})
    },
    store(data = {}, config) {
      const u = url(), formData = new FormData
      for(let k in data)
        formData.append(k, data[k])
      return data === true ? u : axios.post(u, formData, config)

      // let u = url()
      // return args[0] === true ? u : axios.post(u, ...args)
    },
    show(id, ...args) {
      let u = url(id)
      return id === true ? u : axios.get(u, ...args)
    },
    update(id, data = {}, config = {}) {

      const u = url(id), formData = new FormData
      formData.append('_method', 'put')
      // for(let k in data)
      //   formData.append(k, data[k])
      appendArray(formData, data)
      return id === true ? u : axios.post(u, formData, config)

      return id === true ? u : axios.put(u, ...args)
    },
    destroy(id, ...args) {
      let u = url(id)
      return id === true ? u : axios.delete(u, ...args)
    },
    resource(params, config = {}) {
      let u = url('All')
      return params === true ? u : axios.get(u, {...config, params})
    },
    activeOnly(params, config = {}) {
      let u = url('Resource')
      return params === true ? u : axios.get(u, {...config, params})
    }
  }
}

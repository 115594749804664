/*
* Website: https://www.4myth.com
* Email: mythpe@gmail.com
* Mobile: +966590470092
* Copyright © 2018-2020  All rights reserved.
* MyTh Ahmed
*/
import {Stub} from '@helpers/api/requests/Stub'
import axios from 'axios'

const prefix = 'Blog'
const url = p => prefix + (p ? `/${p}` : '')
export default {
  ...Stub(prefix),
  homeView(id, ...args) {
    let u = url(`Home/${id}`)
    return id === true ? u : axios.get(u, ...args)
  }
}

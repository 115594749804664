/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

export default {
  control: 'Control',
  actions: 'Actions',
  name: 'Name',
  first_name: 'First Name',
  last_name: 'Last Name',
  name_ar: 'Arabic Name',
  name_en: 'English Name',
  description: 'Description',
  description_ar: 'Arabic Description',
  description_en: 'English Description',
  login_id: 'Login ID',
  password: 'Password',
  password_confirmation: 'Password Confirmation',
  current_password: 'Current Password',
  email: 'Email',
  mobile: 'Mobile',
  address: 'Address',
  sort_order: 'Sort Order',
  role_id: 'Role',
  image: 'Image',
  type: 'Type',
  notes: 'Notes',
  postcode: 'Postcode',
  code: 'Code',
  status: 'Status',
  date: 'Date',
  phone: 'Phone',
  message: 'Message',
  subject: 'Subject',
  email_contact: 'Email Contact',
  start_date: 'Start Date',
  end_date: 'End Date',
  search: 'Search',
  email_confirmation: 'Email Confirmation',
  roles: 'Roles',
  disable: 'Disable',
  user_id: 'User',
  key: 'Key',
  group: 'Group',
  locale: 'Language',
  contact_number: 'Contact Number',
  your_text: 'Your Message',
  blog_content: 'Blog content',
  blog_date: 'Blog date',
  blog_datetime: 'Blog date',
  blog_image: 'Blog image',
  viewMore: 'View more',
  next_blog: 'Next blog',
  prev_blog: 'Previous blog'
}

<template>
  <v-theme-provider :dark="dark">
    <div>
      <app-style-info-card
          :title="title"
          pace="6"
      >
      </app-style-info-card>

      <app-style-business-contact/>
    </div>
  </v-theme-provider>
</template>

<script>
export default {
  name: 'StyleBusinessInfo',

  props: {dark: Boolean, title: {}},
};
</script>

/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */
import {kebabCase} from 'lodash'

export default {
  methods: {
    route(name, args = {}) {
      name = kebabCase(name)
      return {...args, name}
    },
    isRoute(name) {
      return this.$route.name === name
    }
  },
  computed: {
    AppName() {
      if(this.AppLocale === 'ar') {
        return process.env.MIX_APP_NAME_AR
      }
      return process.env.MIX_APP_NAME_EN
    },
    AppLogo() {
      return require(`@images/${this.themeColor}.png`)
    },
    AppFullLogo() {
      return require(`@images/full-logo.png`)
    }
  }
}

/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

import Auth from '@helpers/auth'
import Vue from '@plugins/vue'
import {HOME_ROUTE_NAME, LOGIN_ROUTE_NAME, USER_HOME_ROUTE_NAME} from '@routes/config'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import routes from './register'

Vue.use(VueRouter)
// console.log(routes)
const options = {
  mode: 'history',
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0,
        options = {
          duration: 1000,
          offset: 0,
          easing: 'linear'
        }

    if(to.hash) {
      scrollTo = to.hash
    }
    else if(savedPosition) {
      scrollTo = savedPosition.y
    }
    let temp = 0
    const nav = () => {
      try {
        if(interval !== temp) {
          // console.log('console.log', interval)
          goTo(scrollTo, options)
          clearInterval(interval)
        }
        else temp = interval
      }
      catch(e) {
        if(interval !== temp) {
          // console.log(e)
          clearInterval(interval)
          setTimeout(nav, 1000)
        }
        else temp = interval
        // setInterval(nav, 200)
      }
    }
    let interval = setInterval(nav, 1000)

    return new Promise((resolve, reject) => {
      resolve(nav(scrollTo, options))
    })
    .then((r) => clearInterval(interval))
    .catch((e) => e)

    // if(to.hash) {
    //   return {
    //     selector: to.hash,
    //     behavior: 'smooth'
    //   }
    // }

    // router.app.$nextTick(() => {
    //   setTimeout(() => goTo(scrollTo, options), 500)
    // })
    // console.log(scrollTo)
    // return goTo(scrollTo,options)
    // return goTo(to.hash ? to.hash : (savedPosition ? savedPosition.y : scrollTo), options)
  }
}

const router = new VueRouter(options)

router.beforeResolve((to, from, next) => {
  router.app.$root.startProgress()
  next()
})

router.afterEach((to, from) => {
  setTimeout(() => router.app.$root.stopProgress(), 250)
  router.app.setAxiosCountdown(200)
})

router.beforeEach((to, from, nav) => {
  const next = (...args) => {
    // router.app.$root.$nextTick(() => {
    nav(...args)
    // nav(to)
    // })
    // console.log()
    // nav((v) => {  console.log(v) })
  }
  let name
  if(to.matched.some(r => r.meta.auth)) {
    if(!Auth.isLogin()) {
      name = LOGIN_ROUTE_NAME
      next({name})
    }
    else {
      next()
    }
  }
  else if(to.matched.some(r => r.meta.guest)) {
    if(Auth.isLogin() && from && from.name === HOME_ROUTE_NAME) {
      next()
      return
    }

    if(Auth.isLogin()) {
      name = USER_HOME_ROUTE_NAME
      next({name})
    }
    else {
      next()
    }
  }
  else {
    next()
  }
})

export default router

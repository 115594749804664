<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2020  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <ValidationObserver
      :ref="name"
      v-slot="v"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-form @submit.prevent="v.handleSubmit(() => submit(v))">
      <slot v-bind="v"></slot>
    </v-form>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'Form',
  props: {
    submit: {
      type: Function,
      default: () => () => null
      // required: true
    },
    name: {
      type: String,
      default: () => 'form'
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    errors: {
      deep: true,
      handler(errors, old) {
        this.setErrors(errors || {});
      }
    }
  },
  computed: {
    form() {
      return this.$refs[this.name];
    }
  },
  methods: {
    reset(...args) {
      this.form && this.form.reset(...args);
    },
    validate(...args) {
      this.form && this.form.validate(...args);
    },
    setErrors(...args) {
      this.form && this.form.setErrors(...args);
    }
  }
};
</script>

<style scoped>

</style>

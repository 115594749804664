/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

import routes from '@routes/config'

export default [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "LandingPage" */ '@views/home/Index'),
    meta: {
      // guest: true,
    },
    children: [
      {
        path: '/',
        name: routes.landing.home.name,
        component: () => import(/* webpackChunkName: "HomePage" */ '@components/home/HomeIndex')
      },
      {
        path: routes.landing.blogs.path,
        name: routes.landing.blogs.name,
        component: () => import(/* webpackChunkName: "HomePageBlogs" */ '@components/home/HomeBlogs')
      },
      {
        path: routes.landing.blog.path,
        name: routes.landing.blog.name,
        component: () => import(/* webpackChunkName: "HomePageBlogs" */ '@components/home/HomeBlogInfo')
      }
    ]
  }
]

/*
* Website: https://www.4myth.com
* Email: mythpe@gmail.com
* Mobile: +966590470092
* Copyright © 2018-2020  All rights reserved.
* MyTh Ahmed
*/
import {Stub} from '@helpers/api/requests/Stub'
import Auth from '@helpers/auth'
import axios from 'axios'

const url = p => 'User' + (p ? `/${p}` : '')

export default {
  sideMenu() {
    return axios.get(`side-menu`)
  },
  profile() {
    return axios.get(url('profile'))
  },
  updateAvatar(f = false) {
    const a = url('profile-avatar')
    return f === !0 ? a : axios.get(a)
  },
  refreshProfile() {
    return this.profile().then((response) => {
      let {data} = response || {}

      if(data && data.data && data.success === true) {
        Auth.save({user: data.data})

      }
      return response
    })
  },
  updateProfile(form = {}) {
    return axios.post(url('profile'), form)
  },
  ...Stub('User')
}

<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2020  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <div v-if="showLogin">
    <v-btn
        v-if="!isRoute(routes.login)"
        :to="route(routes.login)"
        text
        tile
    >
      {{ $t('login') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'GuestButtonsBar',
  data() {
    return {
      routes: this.$config.routes.auth
    };
  },
  computed: {
    showLogin() {
      let login = this.$root.isLogin;
      let error = this.$route.matched.some(r => r.meta.pageError);
      // console.log(login);
      // console.log(this.$root.isLogin , !this.$route.matched.some(r => r.meta.pageError));
      return error ? false : !login;
    }
  }
};
</script>

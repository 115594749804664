/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

export default {
  copyright: "جميع الحقوق محفوظة &copy; <strong>{AppName}</strong> {date}",
  q: '؟',
  progress: 'جارٍ...',
  id: '#',
  ar: 'العربية',
  en: 'English',
  login: 'تسجيل الدخول',
  home: 'الرئيسية',
  done: 'موافق',
  logout: 'تسجيل الخروج',
  cancel: 'إلغاء',
  yes: 'نعم',
  no: 'لا',
  save: 'حفظ',
  change: 'تعديل',
  options: 'خيارات',
  create: 'إضافة',
  control: 'تحكم',
  none: 'لا يوجد',
  export: 'تصدير',
  close: 'إغلاق',
  update: 'تعديل',
  edit: 'تعديل',
  register: 'تسجيل',
  print: 'طباعة',
  data_and: ' و',
  today: 'اليوم',
  day: 'اليوم',
  date: 'التاريخ',
  now: 'الآن',
  am: 'ص',
  pm: 'م',
  error: 'خطأ !!',
  search: 'بحث',
  apply: 'تطبيق',
  index: 'القائمة',
  trashed_index: 'قائمة المحذوفات',
  store: 'إضافة',
  add: 'إضافة',
  forgot_your_password: 'نسيت كلمة المرور',
  whoops: 'اووبس',
  hello: 'مرحباَ',
  regards: 'مع تحياتنا',
  save_changes: 'حفظ التعديلات',
  destroy: 'حذف',
  delete: 'حذف',
  show: 'عرض',
  view: 'عرض',
  back: 'رجوع',
  are_you_sure: 'هل أنت متأكد؟',
  sign_out: 'تسجيل الخروج',
  profile: 'الملف الشخصي',
  details: 'التفاصيل',
  add_new: 'إضافة جديد',
  choose: 'اختر',
  please_fill_in_this_field: 'يرجى ملء هذا الحقل بالشكل الصحيح',
  browse: 'تصفح',
  oh_noes: 'حدث خطأ!',
  last_login: 'أخر دخول',
  disable: 'تعطيل',
  disabled: 'معطل',
  active: 'فعال',
  activate: 'تفعيل',
  filter: 'تصفية',
  restore: 'استرجاع',
  force_delete: 'حذف نهائياً',
  na: '-',
  management: 'الإدارة',
  all: 'الكل',
  selectAll: 'تحديد الكل',
  there_is_an_error: 'يوجد خطأ الرجاء المحاولة لاحقاً',
  NoResults: 'لا توجد نتائج',
  accept: 'قبول',
  accepted: 'مقبول',
  reject: 'رفض',
  pending: 'قيد التنفيذ',
  import: 'استيراد',
  clear: 'مسح',
  default: 'الافتراضي',
  to: 'إلى',
  from: 'من',
  receipt: 'ايصال',
  and: ' و',
  switch: 'تبديل',
  approve: 'قبول',
  confirm: 'تأكيد',
  status: 'الحالة',
  not_sent: 'لم يتم الإرسال',
  showPermissions: 'عرض الصلاحيات',
  exportPdf: 'تصدير البيانات PDF',
  exportExcel: 'تصدير البيانات Excel',
  refreshTable: 'تحديث الصفحة',
  expansionPanelHeaderOptions: 'الأدوات',
  close_window: 'اغلاق النافذة',
  send: 'ارسال',
  website: 'الموقع',
};

/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

import messages from 'vuetify/src/locale/ar.ts'

export default messages
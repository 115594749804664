/*
* Website: https://www.4myth.com
* Email: mythpe@gmail.com
* Mobile: +966590470092
* Copyright © 2018-2020  All rights reserved.
* MyTh Ahmed
*/
import {Stub} from '@helpers/api/requests/Stub'

export default {...Stub('Permission')}

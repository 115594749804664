/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

import Vue from '@plugins/vue';
import _ from 'lodash';

_.mixin(require('lodash-inflection'));

Vue.prototype.$_ = _;
window._ = _;

export default _;
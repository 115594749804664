export default {
  head: "نساعدكِ على اكتشاف مهاراتكِ والتطوير في جميع جوانب حياتكِ",
  line1: "لا يوجد مستحيل لتصبحين سيدة أفضل",
  line2: "هناك بالتأكيد طريقة ما",
  our_logo: "@:landing_page.line1، @:landing_page.line2",
  about_us: "مجموعة من المدربات والمستشارات المعتمدات يجمعنا حب العطاء والتطوير نقدم أفضل الدورات والأمسيات والاستشارات للسيدات",
  vision: "بناء المجتمع بأيدي سيدات عظيمات",
  mission: "تقديم المساعدة والدعم والعطاء للسيدات حول العالم",
  values: {
    line1: "العطاء والتطوير بلا توقف",
    line2: "التواصل الجيد والتعامل الطيب سبيلنا للنجاح",
    line3: "الوقت سلاحنا الأول وانضباطنا به دليل ترابطنا",
  },
  contact_line1: "لديك استشارة ترغب بارسالها لنا؟",
  contact_line2: "تواصل معنا الآن نرحب بكم ونسعد بالرد على استشاراتكم ومساعدتكم",
  contact_title: "نسعد بتواصلك معنا",
  contact_touch: "دائما كن على تواصل معنا",
};

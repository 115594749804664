/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

export default {
  Dashboards: 'Dashboard|Dashboard',
  CPanels: 'CPanel|CPanel',
  CPSchools: 'CPanel|CPanel',
  Users: 'User|Users',
  Profiles: 'Profile|Profile',
  Roles: 'Role|Roles',
  Permissions: 'Permission|Permissions',
  Notifications: 'Notification|Notifications',
  Statuses: 'Status|Statuses',
  Setups: 'Setup|Setup',
  Creates: 'Create|Create',
  Updates: 'Update|Update',
  Edits: 'Edit|Edit',
  Shows: 'Show|Show',
  Trasheds: 'Trashed|Trashed',
  Nationalities: 'Nationality|Nationalities',
  Countries: 'Country|Countries',
  Cities: 'City/Region|Cities/Regions',
  Genders: 'Gender|Genders',
  HealthStatuses: 'Health Status|Health Statuses',
  SocialStatuses: 'Social Status|Social Statuses',
  FinancialStatuses: 'Financial Status|Financial Statuses',
  Imports: 'Import|Imports',
  BlogCategories: 'Blog Category|Blog Categories',
  Blogs: 'Blog|Blog'
}

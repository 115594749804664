/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

export default {
  choose: 'اختر {n}',
  store: 'اضافة {n} جديد',
  update: 'تعديل بيانات {n}',
  show: 'عرض بيانات {n}',
  destroy: 'حذف بيانات {n}',
  rolePermissions: 'صلاحيات وظيفة {n}',
};

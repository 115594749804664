<script>
import Heading from './StyleHeading';

export default {
  name: 'StyleSubheading',

  extends: Heading,

  props: {
    size: {
      type: String,
      default: 'headline',
    },
    sizeMobile: {
      type: String,
      default: 'title',
    },
    weight: {
      type: String,
      default: 'bold',
    },
  },
};
</script>

/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */
import {THEME_DARK_STORAGE} from '@/app/config'
import {mapActions} from 'vuex'

const flipChoice = (data) => {
  const f = {}
  for(let a in data) {
    let c
    c = data[a].split('|')
    c = [c[1], c[0]].join('|')
    f[a] = c

  }
  // console.log(f);
  // console.log(JSON.stringify(f));
  return f
}
export default {
  metaInfo() {
    // const AppName = this.AppName;
    const AppName = process.env.MIX_APP_NAME
    return {
      title: AppName,
      titleTemplate: `%s | ${AppName}`
    }
  },
  data() {
    return {
      IniApp: false,
      AppProgress: false,
      AppFullscreen: false,
      iniAxios: {},
      AxiosCountdown: 100,
      showAxiosErrorMessage: !0,
      email: 'am.of.happiness@gmail.com',
      twitter: '@ambass_happines',
      instagram: 'ambass.happiness',
      mobile: '+966592161517'
    }
  },
  computed: {
    social() {
      return [
        {
          icon: 'mdi-email',
          name: this.parseAttribute('email'),
          title: this.parseAttribute('email'),
          text: `<a href="mailto:${this.$root.email}" target="_blank">${this.$root.email}</a>`,
          url: `mailto:${this.$root.email}`
        },
        {
          icon: 'mdi-phone',
          name: this.parseAttribute('mobile'),
          title: this.parseAttribute('mobile'),
          text: `<a href="tel:${this.mobile}" target="_blank">${this.$root.mobile}</a>`,
          url: `tel:${this.mobile}`
        },
        {
          icon: 'mdi-twitter',
          name: 'Twitter',
          title: 'Twitter',
          text: `<a href="https://www.twitter.com/${this.twitter}" target="_blank">${this.$root.twitter}</a>`,
          url: `https://www.twitter.com/${this.twitter}`
        },
        {
          icon: 'mdi-instagram',
          name: 'Instagram',
          title: 'Instagram',
          text: `<a href="https://www.instagram.com/${this.instagram}" target="_blank">${this.$root.instagram}</a>`,
          url: `https://www.instagram.com/${this.instagram}`
        }
      ]
    }
  },
  methods: {
    ...mapActions('auth', {
      iniAuthFromStorage: 'iniAuthFromStorage',
      refreshUser: 'refresh'
    }),
    updateProfile(user = null) {
      if(user) {
        return new Promise((resolve, reject) => {
          this.authUser = user
          resolve(user)
          return user
        })
      }
      else {
        return this.$api.methods.user.refreshProfile()
        .then(({data}) => {
          if(data && data.data && data.success === true) {
            this.authUser = data.data
            return data.data
          }
          return {}
        })
      }
    },
    iniTheme() {
      let t = localStorage.getItem(THEME_DARK_STORAGE)
      if(t === null) {
        t = 1
      }
      else {
        // console.log(t);
        t = parseInt(t)
      }
      // console.log(t);
      this.themeDark = t
    }
  },
  beforeCreate() {
    this.$axios.interceptors.response.use((response) => response, (error) => {
      const res = () => {
        this.$root.showAxiosErrorMessage = !0
        return Promise.reject(error)
      }
      if(!error || !error.response) {
        return res()
      }

      if(this.$root.showAxiosErrorMessage && error.response.status === 422) {
        this.$nextTick(() => this.alertError(error._message || this.__('messages.error')))
      }

      return res()
    })
  },
  async beforeMount() {
    await this.iniAuthFromStorage()
  },
  created() {
    this.IniAppLocale()
    this.iniTheme()
  }

}

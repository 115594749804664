/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

import qs from 'qs';
import Vue from 'vue';

Vue.prototype.$qs = qs;
window.qs = qs;

export default qs;
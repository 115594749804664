<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2020  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <app-btn
      v-bind="$attrs"
      v-on="$listeners"
      :color="color"
      type="submit"
  >
    <slot></slot>
  </app-btn>
</template>

<script>
export default {
  name: 'Submit',
  props: {
    color: {
      default: () => 'primary'
    }
  }
};
</script>

/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

import routes from '@routes/config';

export default [
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "App" */'@views/App'),
    meta: {
      guest: true,
    },
    children: [
      {
        path: '',
        alias: `/${routes.auth.login}`,
        name: routes.auth.login,
        meta: {
          guest: true,
        },
        component: () => import(/* webpackChunkName: "Login" */ '@components/auth/Login'),
      },
    ],
  },
];

/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

import API_CONFIG from '@/app/api-config'
import {defaultLocale} from '@/app/config'
import Auth from '@helpers/auth'
import routeAllies from '@routes/config'
import axios from 'axios'
import Qs from 'qs'
import Vue from 'vue'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.baseURL = API_CONFIG.baseUrl
axios.defaults.headers.common['Locale'] = defaultLocale
// axios.defaults.headers.common['App-Name'] = process.env.MIX_APP_NAME;
axios.defaults.headers.common['App-Version'] = process.env.MIX_APP_VERSION


// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
// axios.defaults.headers.put['Content-Type'] = 'multipart/form-data';
axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';


let token
if((token = document.head.querySelector('meta[name=\'csrf-token\']'))) {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
}
else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}

let accessToken
if((accessToken = Auth.getAccessToken())) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
}
axios.defaults.paramsSerializer = params => Qs.stringify(params, {arrayFormat: 'indices'})

// console.log(axios.defaults);

axios.interceptors.response.use(function(response) {
  response._message = response?.data?.message
  response._data = response._result = response?.data?.data
  return response
}, function(error) {
  const status = error?.response?.status || null
  let url

  if(status === 401) {
    if(window.location.pathname !== (url = routeAllies.auth.logout.path)) {
      window.location.href = url
    }

  }
  if(status === 403) {
    if(window.location.pathname !== '/' && window.location.pathname !== '') {
      window.location.href = '/'
    }
  }

  error._data = error?.response?.data || {}
  error._errors = error?.response?.data?.errors || {}
  error._message = error?.response?.data?.message || null
  error._status = status
  // console.log(error.response)
  return Promise.reject(error)
})

Vue.prototype.$axios = axios
window.axios = axios

export default axios

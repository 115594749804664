/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

export default {
  Dashboards: 'الاحصائية|الاحصائيات',
  CPanels: 'لوحة التحكم|لوحة التحكم',
  CPSchools: 'لوحة التحكم|لوحة التحكم',
  Users: 'المستخدم|المستخدمين',
  Profiles: 'الملف الشخصي|الملف الشخصي',
  Roles: 'الوظيفة|الوظائف',
  Permissions: 'الصلاحية|الصلاحيات',
  Notifications: 'الإشعار|الإشعارات',
  Statuses: 'الحالة|الحالات',
  Setups: 'الضبط|الضبط',
  Creates: 'إضافة|إضافة',
  Updates: 'تعديل|تعديل',
  Edits: 'تعديل|تعديل',
  Shows: 'عرض|عرض',
  Trasheds: 'المحذوفات|المحذوفات',
  Nationalities: 'الجنسية|الجنسيات',
  Countries: 'الدولة|الدول',
  Cities: 'المدينة/المنطقة|المدن/المناطق',
  Genders: 'الجنس|الأجناس',
  HealthStatuses: 'الحالة الصحية|الحالات الصحية',
  SocialStatuses: 'الحالة الإجتماعية|الحالات الإجتماعية',
  FinancialStatuses: 'الحالة المادية|الحالات المادية',
  Imports: 'استيراد|استيراد',
  BlogCategories: 'نوع المقالة|أنواع المقالة',
  Blogs: 'المقالة|المقالات'
}

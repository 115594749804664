/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

import {Tools} from '@helpers/tools';
import {extend} from 'vee-validate/dist/vee-validate.full.esm';

extend('int', v => Tools.isNumeric(Tools.fromArabicNumber(v)));

extend('mobile', v => Tools.isKsaMobile(v));

export default extend;
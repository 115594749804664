<template>
  <v-theme-provider :dark="dark">
    <div>
      <v-img
          :src="AppLogo"
          contain
          max-width="200"
          min-height="78"
          width="100%"
      />

      <app-style-title
          size="body-1"
          space="4"
          title=""
          weight="regular"
      />

      <app-style-body
          :html="$t('landing_page.about_us')"
          style="color: #ffffff;"
      >

      </app-style-body>

      <!-- <base-btn
           class="mb-12"
           color="white"
           outlined
       >
         More Info
       </base-btn>-->
    </div>
  </v-theme-provider>
</template>

<script>
export default {
  name: 'StyleInfo',

  props: {
    title: String,
    dark: Boolean,
  },
  computed: {},
};
</script>

<template>
  <v-theme-provider :dark="dark">
    <div>
      <app-style-info-card
          :title="title"
          color="primary"
      >
        <slot/>
      </app-style-info-card>

      <template v-for="({ icon, text, title: t }, i) in business">
        <app-style-avatar-card
            :key="i"
            :icon="icon"
            :outlined="false"
            :title="!dense ? t : undefined"
            color="transparent"
            horizontal
            space="0"
        >
          <div style="direction: ltr" v-html="text"/>
        </app-style-avatar-card>

        <v-divider
            v-if="i + 1 !== business.length"
            :key="`divider-${i}`"
            class="my-2"
        />
      </template>
    </div>
  </v-theme-provider>
</template>

<script>
export default {
  name: 'StyleBusinessContact',

  props: {
    dark: Boolean,
    dense: Boolean,
    title: String,
  },

  data() {
    return {
      business: this.$root.social,
    }
  },
}
</script>

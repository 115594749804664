/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

import Vue from "vue";
import Vuex from "vuex";
import modules from "./register";

Vue.use(Vuex);

const
  state = {},
  getters = {},
  actions = {},
  mutations = {};


const options = {
  modules,
  state,
  getters,
  actions,
  mutations
}
// console.log(modules)
export default new Vuex.Store(options)


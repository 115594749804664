/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

import _ from 'lodash'
import moment from 'moment-hijri'
import qs from 'qs'
import goTo from 'vuetify/es5/services/goto'

export const Tools = {
  goTo(target = 0, GoToOptions = {}) {
    GoToOptions = {
      easing: 'linear',
      duration: 500,
      ...GoToOptions
    }
    return goTo(target, GoToOptions)
  },
  isString: (...value) => _.isString(...value),
  isBoolean: (...value) => _.isBoolean(...value),
  isFunction: (...value) => _.isFunction(...value),
  isArray: (...value) => _.isArray(value) || _.isObject(value),
  isOnlyArray: (...value) => _.isArray(...value),
  isOnlyObject: (...value) => _.isObject(...value),
  isNumeric: v => !isNaN(parseFloat(v)) && isFinite(v) && v.constructor !== Array,

  isKsaMobile: mobile => {
    mobile = Tools.fromArabicNumber(mobile) || ''
    if(!mobile) return !1
    const c1 = mobile.substr(0, 2)
    const c2 = parseInt(mobile.substr(0, 1))
    return mobile &&
        ((mobile.length === 10 && (c1 === '05' || parseInt(c1) === 5)) || (mobile.length === 9 && c2 === 5))
  },
  findBy(search, value, column = 'id') {
    return _.find(search, e => this.isArray(e) ? e[column] === value : e === value)
  },
  queryStringify: v => new URLSearchParams(qs.stringify(v, {
    arrayFormat: 'indices'
    // encodeValuesOnly: true,
    // encode: false,
  })),
  downloadFromResponse(response) {
    if(!response) return

    let name = response.headers['content-disposition'] || ''
    name = name.split('filename=').pop().replace(/^\"+|\"+$/g, '') || null
    // console.log(response.headers)
    // if(!name) return;
    // console.log(name)
    var fileURL = window.URL.createObjectURL(new Blob([response.data]))
    var fileLink = document.createElement('a')
    if(!fileLink || !fileURL) return

    fileLink.href = fileURL
    fileLink.setAttribute('download', name)
    document.body.appendChild(fileLink)
    fileLink.click()
    setTimeout(() => {
      document.body.removeChild(fileLink)
      URL.revokeObjectURL(fileURL)
    }, 3000)
  },

  fromArabicNumber(n) {
    if(!n) return n
    n = '' + n
    n = n.replace(/٠/g, 0)
    .replace(/١/g, 1)
    .replace(/٢/g, 2)
    .replace(/٣/g, 3)
    .replace(/٤/g, 4)
    .replace(/٥/g, 5)
    .replace(/٦/g, 6)
    .replace(/٧/g, 7)
    .replace(/٨/g, 8)
    .replace(/٩/g, 9)

    return '' + n
  },
  toNumber(n) {
    if(!n) return n
    n = '' + n
    n = this.fromArabicNumber(n)
    n = n.replace(/\,/g, '')
    // .replace(/[^\d+.-]/g, "");

    return '' + n
  },
  toNumberFormat(num = null) {
    // console.log(num);

    if(!num) return num

    const splitArray = this.toNumber(num).split('.')
    const rgx = /(\d+)(\d{3})/

    while(rgx.test(splitArray[0])) {
      splitArray[0] = splitArray[0].replace(rgx, '$1' + ',' + '$2')
    }
    // console.log(splitArray);
    return splitArray.join('.')
  },
  todayDate() {
    const l = moment().locale()
    moment.locale('en')
    const date = moment().format('YYYY-MM-DD')
    moment.locale(l)
    return date
  },
  todayTime() {
    const l = moment().locale()
    moment.locale('en')
    const time = moment().format('HH:mm')
    moment.locale(l)
    return time
  }
}

export default {Tools}

/*
*
  toQueryString(key, value) {
    // console.log((new URLSearchParams(value)).toString(),encodeURIComponent(key))
    // console.log(encodeURIComponent(value))
    // console.log(`${encodeURIComponent(key)}=${(new URLSearchParams(value)).toString()}`)
    // return `${encodeURIComponent(key)}=${(new URLSearchParams(value)).toString()}`
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

  },
  parsQueryString(obj, namespace) {
    let f = [];
    // console.log(namespace, obj)
    Object.keys(obj).forEach((k) => {
      k = encodeURIComponent(k);
      let key = namespace ? `${namespace}[${k}]` : k;
      let v = obj[k];
      if(v && _.isArray(v) || _.isObject(v)) {
        // let e = this.parsQueryString(v, key);
        // console.log(e);
        f.push(this.parsQueryString(v, key));
      }
      else {
        // console.log(key, v)
        f.push(this.toQueryString(key, v));
      }
    });
    // console.log(obj, namespace)
    // console.log(f)
    return f.join('&');
  },
  aqueryString(value) {
    let f = [];
    if(_.isString(value) || !value) return value;
    return this.parsQueryString(value);
    Object.keys(value).forEach((k) => {
      let v = value[k];
      if(v && !_.isString(v)) {
        f.push(this.parsQueryString(value[k], k));
      }
      else {
        // console.log(k)
        // f.push(this.toQueryString(k, v));
      }
    });
    // console.log(f);
    return f.join('&');
    console.log(qs.stringify({o: {asdf: [1, 4, 5], f: {a: 'qwe', b: '12312321'}}, p: [2, 4, 4]}));

  },

  * */

/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

import routes from '@routes/config';

export default [
  {
    path: '/panel',
    component: () => import( /* webpackChunkName: "App" */ '@views/App'),
    meta: {
      auth: true
    },
    children: [
      {
        /* Main Auth Route */
        path: 'dashboard',
        name: routes.user.home,
        component: () => import(/* webpackChunkName: "Dashboard" */ '@components/dashboard/dashboard')
      },

      {
        path: routes.role.index,
        name: routes.role.index,
        component: () => import(/* webpackChunkName: "roles" */ '@components/actor/role/Index')
      },

      {
        path: routes.permission.index,
        name: routes.permission.index,
        component: () => import(/* webpackChunkName: "permissions" */ '@components/actor/permission/Index')
      },

      {
        path: 'profile',
        name: routes.user.profile,
        component: () => import(/* webpackChunkName: "Profile" */ '@actor/user/Profile')
      },

      {
        path: routes.user.index,
        name: routes.user.index,
        component: () => import(/* webpackChunkName: "Users" */ '@actor/user/Index')
      },

      {
        path: routes.blogCategory.index,
        name: routes.blogCategory.index,
        component: () => import(/* webpackChunkName: "BlogCategories" */ '@components/blog-category/Index')
      },
      {
        path: routes.blog.index,
        name: routes.blog.index,
        component: () => import(/* webpackChunkName: "Blogs" */ '@components/blog/Index')
      },
    ]
  }
];

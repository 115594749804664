/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'

export default {
  login: post => {
    return axios.post('login', post)
  },
  logout() {
    return axios.get('logout')
  },
  checkToken() {
    return axios.get('check-token')
  }
}
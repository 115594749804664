<template>
  <v-theme-provider :dark="dark">
    <v-container class="pa-0">
      <v-row
          :justify="justify"
          no-gutters
      >
        <v-col
            v-if="icon"
            :class="`text-${align}`"
            class="mb-4"
            cols="12"
        >
          <app-style-icon :color="color">
            {{ icon }}
          </app-style-icon>
        </v-col>

        <v-col
            v-if="text || title || subtitle"
            :cols="callout ? 9 : 12"
        >
          <app-style-subtitle
              v-if="subtitle"
              :title="subtitle"
              space="1"
          />

          <app-style-title
              :title="title"
              class=""
              space="1"
          />

          <app-style-divider
              v-if="title || subtitle"
              :align="this.$root.$vuetify.rtl ? 'right':'left'"
              :color="color"
          />

          <app-style-body
              v-if="text || $slots.default"
              :text="text"
              space="6"
          >
            <slot/>
          </app-style-body>
        </v-col>

        <v-col
            v-if="callout"
            cols="2"
        >
          <div
              class="display-3 grey--text text--lighten-4 font-weight-bold pr-8"
              v-text="callout"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
// Mixins
import Heading from '@mixins/HeadingStyle';

export default {
  name: 'StyleInfoCard',

  mixins: [Heading],

  props: {
    dark: Boolean,
    callout: String,
    color: {
      type: String,
      default: 'primary',
    },
    icon: String,
    subtitle: String,
    text: String,
    title: String,
  },
};
</script>

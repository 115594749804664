/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

import {defaultLocale, rtl} from '@/app/config';
import Vue from '@plugins/vue';
import i18n, {locales} from '@plugins/vue-i18n';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const opts = {
  rtl,
  theme: {
    dark: !0,
    options: {
      customProperties: !0,
    },
    themes: {
      light: {
        primary: '#a0000b',
        secondary: '#fdd4d6',
        accent: '#562d69',
      },
      dark: {
        primary: '#a0000b',
        secondary: '#fdd4d6',
        accent: '#562d69',
      },
    },
  },
  lang: {
    current: defaultLocale,
    defaultLocale,
    t: (key, ...params) => i18n.t(key, params),
    locales,
  },

};

export default new Vuetify(opts);

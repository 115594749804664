/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

export default {
  control: 'تحكم',
  actions: 'خيارات',
  name: 'الاسم',
  first_name: 'الاسم الاول',
  last_name: 'الاسم الاخير',
  name_ar: 'الاسم العربي',
  name_en: 'الاسم الإنجليزي',
  description: 'الوصف',
  description_ar: 'الوصف العربي',
  description_en: 'الوصف الإنجليزي',
  login_id: 'حقل تسجيل الدخول',
  password: 'كلمة المرور',
  password_confirmation: 'تأكيد كلمة المرور',
  current_password: 'كلمة المرور الحالية',
  email: 'البريد الالكتروني',
  mobile: 'الجوال',
  address: 'العنوان',
  sort_order: 'ترتيب العرض',
  role_id: 'الوظيفة',
  image: 'الصورة',
  type: 'النوع',
  notes: 'ملاحظات',
  postcode: 'الرمز البريدي',
  code: 'الكود',
  status: 'الحالة',
  date: 'التاريخ',
  phone: 'الهاتف',
  message: 'الرسالة',
  subject: 'الموضوع',
  email_contact: 'بريد التواصل معنا',
  start_date: 'تاريخ البداية',
  end_date: 'تاريخ النهاية',
  search: 'بحث',
  email_confirmation: 'تأكيد البريد الإلكتروني',
  roles: 'الوظائف',
  disable: 'معطل',
  user_id: 'المستخدم',
  key: 'المفتاح',
  group: 'المجموعة',
  locale: 'اللغة',
  contact_number: 'رقم التواصل',
  your_text: 'رسالتك او استفسارك',
  blog_category_id: 'نوع المقالة',
  blog_id: 'المقالة',
  blog_subject: 'عنوان المقالة',
  blog_content: 'محتوى المقالة',
  blog_date: 'تاريخ المقالة',
  blog_datetime: 'تاريخ المقالة',
  blog_image: 'صورة المقالة',
  viewMore: 'تحميل المزيد',
  next_blog: 'المقالة التالية',
  prev_blog: 'المقالة السابقة',
  fixed_blog: 'مقالة ثابتة',
}

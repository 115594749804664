/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

const files = require.context('@plugins', true, /\index.js$/i)

files.keys().map((key) => files(key))
export default {
  name: 'HeadingStyle',

  inject: {
    heading: {
      default() {
        return this.$vuetify.rtl ? 'right' : 'left'
      },
    },
  },
  provide() {
    return {
      heading: {
        align: this.align,
      },
    }
  },
  props: {
    align: {
      type: String,
      default() {
        return this.heading.align
      },
    },
  },
  computed: {
    justify() {
      switch (this.align) {
        case 'center':
          return 'center'
        case 'right':
          return 'end'
        default:
          return 'start'
      }
    },
  },
}

<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2020  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <div>
<!--    <v-footer padless elevation="3">-->
<!--      <v-row justify="center" no-gutters>-->
<!--        <v-col class="gray py-3 text-center" cols="12">-->
<!--          {{ new Date().getFullYear() }} — <strong>MyTh</strong>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--    </v-footer>-->
  </div>
</template>

<script>
export default {
  name: "MainFooter"
}
</script>

<style scoped>

</style>
/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

export default {
  contact_us: "Contact Us",
  about_us: "About",
  vision: "Vision",
  mission: "Mission",
  our_logo: "Logo",
  follow_us: "Follow Us",
  mail_us_your_message: "Mail us your message",
};

/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

export default {
  areYouSure: 'Are you sure?',
  sure_logout: 'Are you sure the account will be logged out?',
  exportAll: 'Are you sure to export all data?',
  confirmDelete: 'Are you sure to delete data?',
  confirmAcceptEmpPermitDemand: 'Are you sure you accept the employee permit?',
  error: 'An error occurred, please try again later.',
  error_422: 'The Given Data Was Invalid',
  whoops: 'Whoops',
  not_found_message: 'The request not found',
  noMembership: 'There is no subscription to your school. Please contact the administration',
  window_blocked: 'Please make sure to allow pop-ups in your browser to be able to download files. It is recommended to use the Google Chrome browser for all the services and features of the program.',
  plzEnterData: 'Please enter data',
  emailRequired: 'Email is required',
  validEmail: 'It must be a valid mail',
};

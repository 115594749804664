<template>
  <div>
    <app-style-info-card
        :subtitle="subtitle"
        :title="title"
        color="primary"
        space="4"
    />
    <v-form
        ref="form"
        v-model="valid"
    >
      <app-style-text-field
          v-model="form.name"
          :disabled="this.loading"
          :label="parseAttribute('name')"
          :rules="required"
      />

      <app-style-text-field
          v-model="form.email"
          :disabled="this.loading"
          :label="parseAttribute('email')"
          :rules="emailRules"
          type="email"
      />

      <app-style-text-field
          v-model="form.subject"
          :disabled="this.loading"
          :label="parseAttribute('subject')"
          :rules="required"
      />

      <app-style-textarea
          v-model="form.message"
          :disabled="this.loading"
          :label="parseAttribute('your_text')"
          :rules="required"
          class="mb-6"
      />

      <app-style-btn
          :color="!theme.isDark ? 'primary' : 'white'"
          :loading="loading"
          outlined
          @click.prevent="sendContact(form)"
      >
        {{ parseAttribute('send') }}
      </app-style-btn>
    </v-form>
  </div>
</template>

<script>

export default {
  name: 'StyleContactForm',
  inject: ['theme'],
  props: {
    subtitle: String,
    title: {
      type: String,
      default() {
        return this.parseAttribute('mail_us_your_message')
      }
    }
  },
  methods: {
    sendContact(form) {

      if(!this.$refs.form.validate()) return

      if(this.loading) return
      this.loading = !0

      let message = this.parseAttribute('messages.error')
      this.$axios.post('contact-us', form)
      .then(({data, _message}) => {
        if(data && data.message) {
          message = _message || message
          this.resetForm()
        }
        alert(message)
      })
      .catch(({_message, response}) => {
        // if(response.status === 422) {
        //   message = this.parseAttribute('messages.error_422')
        // }
        alert(_message || message)
        // console.log(response)
      })
      .finally(() => {
        this.loading = !1
      })
    },
    resetForm() {
      this.form.name = null
      this.form.email = null
      this.form.subject = null
      this.form.message = null
      this.$refs.form.reset()
    }
  },
  data() {
    return {
      valid: false,
      loading: !1,
      required: [
        v => !!v || this.parseAttribute('messages.plzEnterData')
      ],
      emailRules: [
        v => !!v || this.parseAttribute('messages.emailRequired'),
        v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.parseAttribute('messages.validEmail')
      ],
      form: {
        name: null,
        email: null,
        subject: null,
        message: null
      }
    }
  }
}
</script>

<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2020  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <v-container
      v-bind="$attrs"
      v-on="$listeners"
  >
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: "Container",
}
</script>
<template>
  <div
      :class="classes"
      class="pt-2"
  >
    <app-style-avatar
        v-if="icon"
        :color="color"
        :dark="dark"
        :icon="icon"
        :outlined="outlined"
        :size="size"
        class="mb-3"
    />

    <div :class="horizontal && title && 'ms-6'">
      <app-style-title
          :title="title"
          class="text-uppercase"
          space="3"
      />

      <app-style-body
          v-if="text || $slots.default"
          :space="horizontal ? 0 : undefined"
          :text="text"
          class="mx-auto"
          max-width="700"
      >
        <slot/>
      </app-style-body>
    </div>
  </div>
</template>

<script>
// Mixins
import Heading from '@mixins/HeadingStyle';

export default {
  name: 'StyleAvatarCard',

  mixins: [Heading],

  props: {
    align: {
      type: String,
      default() {
        return this.$vuetify.rtl ? 'right' : 'left';
      },
    },
    color: String,
    dark: Boolean,
    horizontal: Boolean,
    icon: String,
    outlined: {
      type: Boolean,
      default: true,
    },
    space: {
      type: [Number, String],
      default: 8,
    },
    size: {
      type: [Number, String],
      default: 72,
    },
    text: String,
    title: String,
  },

  computed: {
    classes() {
      const classes = [
        `mb-${this.space}`,
      ];

      if (this.horizontal) {
        classes.push('d-flex');

        if (!this.$slots.default && !this.text) {
          classes.push('align-center');
        }
      }

      return classes;
    },
  },
};
</script>

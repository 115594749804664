/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

export default {
  areYouSure: 'هل أنت متأكد؟',
  sure_logout: 'هل أنت متأكد سوف يتم تسجيل الخروج من الحساب',
  exportAll: 'هل أنت متأكد من تصدير جميع البيانات؟',
  confirmDelete: 'هل أنت متأكد من حذف البيانات؟',
  confirmAcceptEmpPermitDemand: 'هل أنت متأكد من قبول طلب الإستئذان؟',
  error: 'حدث خطأ الرجاء المحاولة لاحقاً',
  error_422: 'الرجاء التأكد من جميع البيانات',
  whoops: 'عذراً',
  not_found_message: 'لم يتم العثور على البيانات المطلوبة',
  noMembership: 'لا يوجد اشتراك بالمدرسة الخاصة بك الرجاء التواصل مع الإدارة',
  window_blocked: 'الرجاء التأكد من سماح فتح النوافذ المنبثقه في المتصفح لتستطيع تحميل الملفات. ينصح باستخدام متصفح قوقل كروم (Google Chrome) للحصول على جميع الخدمات والمميزات في البرنامج.',
  plzEnterData: 'الرجاء ادخال البيانات',
  emailRequired: 'البريد الإلكتروني مطلوب',
  validEmail: 'يجب ان يكون بريد صحيحا',
};

<script>
import Header from '@mixins/HeadingStyle';
import Heading from './StyleHeading';

export default {
  name: 'StyleTitle',

  extends: Heading,

  mixins: [Header],

  props: {
    size: {
      type: String,
      default: 'title',
    },
    mobileSize: {
      type: String,
      default: 'subtitle-1',
    },
    tag: {
      type: String,
      default: 'h3',
    },
    weight: {
      type: String,
      default: 'bold',
    },
  },
};
</script>

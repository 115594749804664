<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2020  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <v-fab-transition id="submit-data-table-fab">
    <app-btn
        v-bind="$attrs"
        v-on="$listeners"
        :color="color"
        bottom
        class="v-btn-submit-data-table"
        dark
        :fab="false"
        left
        large
        type="submit"
    >
      <slot></slot>
    </app-btn>
  </v-fab-transition>
</template>

<script>
export default {
  name: 'SubmitDt',
  props: {
    color: {
      default: () => 'primary'
    }
  }
}
</script>
<style>
.v-btn-submit-data-table {
  bottom: 0;
  position: fixed !important;
  margin: 0 0 16px 16px;
}
</style>

<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2020  All rights reserved.
  - MyTh Ahmed
  -->
<script>
import NumberInput from '@base-inputs/NumberInput';

export default {
  name: 'PriceInput',
  extends: NumberInput,
  props: {
    format: {
      type: Boolean,
      default: () => true
    },
    minus: {
      type: Boolean,
      default: () => true
    }
  }
};
</script>

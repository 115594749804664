/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

export default {
  copyright: 'Copyright &copy; <strong>{AppName}</strong> {date} All rights reserved.',
  q: '?',
  progress: 'progress ...',
  id: '#',
  ar: 'العربية',
  en: 'English',
  login: 'Login',
  home: 'Home',
  done: 'Done',
  logout: 'Logout',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  save: 'Save',
  change: 'Change',
  options: 'options',
  create: 'create',
  control: 'control',
  none: 'None',
  export: 'Export',
  close: 'Close',
  update: 'Update',
  edit: 'Edit',
  register: 'Register',
  print: 'Print',
  data_and: ' And ',
  today: 'Today',
  day: 'Day',
  date: 'Date',
  now: 'Now',
  am: 'AM',
  pm: 'PM',
  error: 'Error !!',
  search: 'Search',
  index: 'List',
  trashed_index: 'Trashed List',
  apply: 'Apply',
  store: 'Add',
  add: 'Add',
  whoops: 'Whoops',
  hello: 'Hello',
  regards: 'Regards',
  forgot_your_password: 'Forgot Your Password?',
  save_changes: 'Save Changes',
  destroy: 'Delete',
  delete: 'Delete',
  show: 'Show',
  view: 'View',
  back: 'Back',
  are_you_sure: 'Are you sure ?',
  sign_out: 'Sign out',
  profile: 'Profile',
  details: 'Details',
  add_new: 'Add New',
  choose: 'Choose',
  please_fill_in_this_field: 'Please fill in this field',
  browse: 'Browse',
  oh_noes: 'Oh Noes!!',
  last_login: 'Last Login',
  disable: 'Disable',
  disabled: 'Disabled',
  activate: 'Activate',
  active: 'Enabled',
  filter: 'Filter',
  restore: 'Restore',
  force_delete: 'Force Delete',
  na: '-',
  management: 'Management',
  all: 'All',
  selectAll: 'Select All',
  there_is_an_error: 'There is an error',
  NoResults: 'No Results',
  accept: 'Accept',
  accepted: 'Accepted',
  reject: 'Reject',
  pending: 'Pending',
  import: 'Import',
  clear: 'Clear',
  default: 'Default',
  to: 'To',
  from: 'From',
  receipt: 'Receipt',
  and: ' And ',
  switch: 'Switch',
  approve: 'Approve',
  confirm: 'Confirm',
  status: 'Status',
  not_sent: 'Not Sent',
  showPermissions: 'Show Permissions',
  exportPdf: 'Export as PDF',
  exportExcel: 'Export as Excel',
  refreshTable: 'Refresh Table',
  expansionPanelHeaderOptions: 'Tools',
  close_window: 'Close Window',
  send: 'Send',
  website: 'Website',
};

/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

export default {
  name: "Profile",
  update: "Update Profile",
  photo: "Profile photo",
  account_information: "Account Information",
  personal_information: "Personal Information",
}
<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2020  All rights reserved.
  - MyTh Ahmed
  -->

<template>
<!--  <app-loading v-if="!$root.IniApp" />-->
<!--  <template v-else>-->
    <v-fade-transition mode="out-in">
      <router-view></router-view>
    </v-fade-transition>
<!--  </template>-->
</template>

<script>
export default {
  name: "AppRouter",
  components: {
    AppLoading: () => import("@views/AppLoading")
  },
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>

<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2020  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <v-sheet
      v-if="loading"
      v-bind="$attrs"
      :class="getClasses"
      :color="color"
  >
    <v-responsive
        class="mx-auto"
        max-width="100%"
    >
      <v-row>
        <template v-for="i in count">
          <v-skeleton-loader
              v-bind="$attrs"
              :type="type"
              class="mx-auto mb-1"
              :width="width"
          >
          </v-skeleton-loader>
        </template>
      </v-row>
    </v-responsive>
  </v-sheet>
</template>

<script>
export default {
  name: 'Skeleton',
  props: {
    loading: {
      type: Boolean,
      required: !0
    },
    count: {
      type: [String, Number],
      default: () => 1
    },
    color: {
      type: String,
      default: () => 'transparent'
    },
    type: {
      type: String,
      default: () => 'list-item-avatar'
    },
    width: {
      type: [String,Number],
      default: () => 300
    }
  },
  computed: {
    getClasses: () => [
      'pa-0'
      // 'pt-5',
    ].join(' ')
  }
}
</script>

<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2020  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <app-col-span v-bind="$attrs">
    <slot name="top"></slot>
    <template v-if="showLabel">
      <component :is="labelTag">{{getLabel}}</component>
    </template>
    <ckeditor
        v-bind="$attrs"
        v-on="$listeners"
        :config="geConfig"
        :label="getLabel"
        :placeholder="getPlaceholder"
        :value="value"
        v-on:input="$emit('input',$event)"
    />
    <slot></slot>
    <slot name="bottom"></slot>
  </app-col-span>
</template>

<script>
export default {
  name: 'TextEditor',
  props: {
    value: {},
    name: {
      required: true
    },
    label: {},
    showLabel: { default: () => true},
    labelTag: { default: () => 'h3'},
    config: {}
  },
  computed: {
    geConfig() {
      return {
        defaultLanguage: this.AppLocale,
        language: this.AppLocale,
        contentsLangDirection: this.AppDirection,
        ...this.config
      }
    },
    getLabel() {
      let k = this.label || this.name
      return this.parseAttribute(k)
    },
    getPlaceholder() {
      let k = this.placeholder || this.name
      return this.parseAttribute(k)
    }
  }
}
</script>

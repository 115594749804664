/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2020  All rights reserved.
 * MyTh Ahmed
 */

export default {
  name: "الملف الشخصي",
  update: "تعديل الملف الشخصي",
  photo: "صورة الملف الشخصي",
  account_information: "بيانات الحساب",
  personal_information: "البيانات الشخصية",
}